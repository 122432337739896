<template>
  <div :id="apartment.id">
    <header>
      <h2>Skontaktuj się z nami</h2>
    </header>
    <p>
      Jesteś zainteresowany powyższą nieruchomością? Napisz do nas korzystająć z
      poniższego formularza, a my skontaktujemy się z Tobą tak szybko jak to
      możliwe.
    </p>
    <form @submit.prevent="onFormSubmit" novalidate="true">
      <div class="form-group">
        <div class="form-control">
          <label for="name">Imię i nazwisko</label>
          <input type="text" id="name" name="name" v-model="name" />
          <p class="error" v-if="displayErrors && nameNotValid">
            Pole zawierające imię i nazwisko nie może być puste i musi zawierać
            conajmniej 5 znaków
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="mail">Adres email</label>
          <input type="email" id="mail" name="mail" v-model="mail" />
          <p class="error" v-if="displayErrors && mailNotValid">
            Pole zawierające adres mailowy nie może być puste i musi zawierać
            poprawny adres e-mail.
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="phone">Telefon</label>
          <input type="text" id="phone" name="phone" v-model="phone" />
          <p class="error" v-if="displayErrors && phoneNotValid">
            Pole zawierające telefon nie może być puste i nie może zawierać
            liter.
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="title">Tytuł</label>
          <input type="text" id="title" name="title" v-model="title" />
          <p class="error" v-if="displayErrors && titleNotValid">
            Pole zawierające tytuł nie może być puste i musi zawierać conajmniej
            5 znaków.
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="msg">Treść</label>
          <textarea name="msg" id="msg" v-model="msg"></textarea>
          <p class="error" v-if="displayErrors && msgNotValid">
            Pole zawierające treść nie może być puste i musi zawierać conajmniej
            10 znaków.
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <div class="row">
            <input
              type="checkbox"
              name="agreement"
              id="agreement"
              v-model="check"
            />
            <label for="agreement"
              >Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z
              <router-link to="/">regulaminem</router-link></label
            >
          </div>
          <p class="error" v-if="displayErrors && checkNotValid">
            Aby wysłać formularz zgoda jest wymagana.
          </p>
        </div>
      </div>
      <p>
        <small
          ><i
            >Wysyłając wiadomość wyrażasz zgodę na przetwarzanie danych
            osobowych przez Forum Sp. z o. o. lorem ipsum dolor sit amet</i
          ></small
        >
      </p>
      <div class="res" :class="{ valid: isValid }" v-if="hasMsg">
        <div v-for="msg in resMsgs" :key="msg">
          {{ msg.msg }}
        </div>
      </div>
      <button class="btn base" type="submit">Wyślij</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["apartment"],
  data() {
    return {
      hasMsg: false,
      isValid: true,
      resMsgs: [],
      displayErrors: false,
      nameNotValid: false,
      mailNotValid: false,
      phoneNotValid: false,
      titleNotValid: false,
      msgNotValid: false,
      check: false,
      name: "",
      mail: "",
      phone: "",
      title: "Zapytanie dot. mieszkania " + this.apartment.title,
      msg:
        "Witam, jestem zainteresowany mieszkaniem " +
        this.apartment.title +
        " i proszę o kontakt.",
    };
  },
  methods: {
    onFormSubmit() {
      this.displayErrors = false;
      if (this.validate()) {
        this.submitForm();
      } else {
        this.displayErrors = true;
      }
    },
    validate() {
      const validName = this.validateName();
      const validEmail = this.validateMail();
      const validPhone = this.validatePhone();
      const validTitle = this.validateTitle();
      const validMsg = this.validateMsg();
      const validCheck = this.validateCheck();
      return (
        validName &&
        validEmail &&
        validPhone &&
        validTitle &&
        validMsg &&
        validCheck
      );
    },
    validateName() {
      this.nameNotValid = false;
      if (this.name.trim().length < 5) {
        this.nameNotValid = true;
      }
      return !this.nameNotValid;
    },
    validateMail() {
      this.mailNotValid = false;
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.mail.toLowerCase()) || this.mail.trim().length === 0) {
        this.mailNotValid = true;
      }
      return !this.mailNotValid;
    },
    validatePhone() {
      this.phoneNotValid = false;
      const re = /^[^a-z^A-Z]*$/;
      if (
        !re.test(this.phone.toLowerCase()) ||
        this.phone.trim().length === 0
      ) {
        this.phoneNotValid = true;
      }

      return !this.phoneNotValid;
    },
    validateTitle() {
      this.titleNotValid = false;
      if (this.title.trim().length < 5) {
        this.titleNotValid = true;
      }
      return !this.titleNotValid;
    },
    validateMsg() {
      this.msgNotValid = false;
      if (this.msg.trim().length < 10) {
        this.msgNotValid = true;
      }
      return !this.msgNotValid;
    },
    validateCheck() {
      this.checkNotValid = false;
      if (!this.check) {
        this.checkNotValid = true;
      }
      return !this.checkNotValid;
    },
    async submitForm() {
      try {
        const formData = {
          name: this.name,
          mail: this.mail,
          phone: this.phone,
          title: this.title,
          msg: this.msg,
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/send-apartment`,
          formData
        );

        this.hasMsg = true;
        this.isValid = true;
        this.formData = this.initFormData;
        this.resMsgs = [];
        this.resMsgs.push(response.data);
        this.formData = this.initFormData;
      } catch (err) {
        this.isValid = false;
        this.hasMsg = true;
        this.resMsgs = err.response.data.errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_base.scss";
.res {
  margin-bottom: 1rem;
  color: red;
  &.valid {
    color: green;
  }
}
form {
  .form-group {
    display: flex;
    width: 100%;
    .form-control {
      width: 100%;
      margin-bottom: 1rem;
      &.row {
        display: flex;
        flex-direction: row;
      }
      input,
      select,
      textarea {
        font-family: inherit;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        color: $fontLight;
        &[type="checkbox"] {
          width: auto;
        }
      }
      textarea {
        resize: none;
        height: 200px;
      }
      &.mr {
        margin-right: 1rem;
      }
    }
    .error {
      color: red;
    }
  }
}
</style>