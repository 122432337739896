<template>
  <div>
    <header class="hflex" v-if="this.isLoggedIn">
      <button class="btn base" @click="editApartment">Edytuj mieszkanie</button>
      <button class="btn outline ml-2" @click="deleteApartment">
        Usuń mieszkanie
      </button>
    </header>
    <div class="gallery">
      <div class="main">
        <img :src="getImgUrl(activePic)" alt="mieszkanie" />
      </div>
      <div class="small">
        <div
          v-for="(img, index) of apartment.apartmentBackgrounds"
          :key="index"
        >
          <img @click="setActivePic(img?.path)" :src="getImgUrl(img?.path)" />
        </div>
      </div>
    </div>
    <div class="desc">
      <header>
        <div>
          <h2>{{ apartment.title }}</h2>
          <h4>{{ apartment.city }}, {{ apartment.district }}</h4>
        </div>
        <a class="btn base" :href="apartment.link" target="_blank"
          >Zobacz mieszkanie</a
        >
      </header>
      <section>
        <header>
          <h3>Szczegóły ogłoszenia</h3>
        </header>
        <div class="details">
          <div class="details-column">
            <div class="details-item">
              <span>Czynsz: </span> {{ apartment.rent }}
            </div>
            <div class="details-item">
              <span>Liczba pokoi: </span> {{ apartment.rooms }}
            </div>
            <div class="details-item">
              <span>Liczba pięter: </span> {{ apartment.floorNumberInBuilding }}
            </div>
            <div class="details-item">
              <span>Piętro: </span> {{ apartment.floor }}
            </div>
          </div>
          <div class="details-column">
            <div class="details-item">
              <span>Kaucja: </span> {{ apartment.deposit }}
            </div>
            <div class="details-item">
              <span>Budynek: </span> {{ apartment.buildingType }}
            </div>
            <div class="details-item">
              <span>Dostępne od: </span> {{ avaiableFrom }}
            </div>
          </div>
          <div class="details-column">
            <div class="details-item">
              <span>Powierzchnia: </span> {{ apartment.surface }}
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="modal" v-if="modalIsOpen">
      <header>Usuwanie mieszkania</header>
      <p>Czy na pewno chcesz usunąć to mieszkanie?</p>
      <div class="flex-between">
        <button class="btn base" @click="cancel">Anuluj</button>
        <button class="btn outline ml-2" @click="deletePermanently">
          Usuń
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import { mapGetters } from "vuex";
import ApartmentService from "../../services/ApartmentService";
export default {
  props: ["apartment"],
  data() {
    return {
      activePic: this.apartment.apartmentBackgrounds[0]?.path,
      isDescVisible: false,
      modalIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    buttonText() {
      return this.isDescVisible ? "Zwiń" : "Czytaj dalej";
    },
    avaiableFrom() {
      return this.apartment.avaiableFrom.split("T")[0];
    },
  },
  methods: {
    cancel() {
      this.modalIsOpen = false;
    },
    async deletePermanently() {
      try {
        await ApartmentService.delete(this.$route.params.id);
        toast.success("Mieszkanie zostało usunięte.");
        this.$router.push("/dashboard");
      } catch (err) {
        toast.error("Nie udało się usunąć mieszkania. Spróbuj później.");
      }
    },
    showDesc() {
      this.isDescVisible = !this.isDescVisible;
    },
    getImgUrl(pic) {
      if (!pic) {
        return "";
      }
      return `${process.env.VUE_APP_API_URL}/` + pic;
    },
    setActivePic(img) {
      this.activePic = img;
    },
    editApartment() {
      this.$router.push(`/edit/${this.$route.params.id}`);
    },
    deleteApartment() {
      this.modalIsOpen = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";
.flex-between {
  display: flex;
  justify-content: space-between;
  width: 65%;
}
.modal {
  width: 30vw;
  height: 30vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  background: white;
  padding: 1rem;
  border-top: 8px solid $primary;
  box-shadow: $dark;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  header {
    font-size: 2rem;
    font-weight: bold;
  }
}
.hflex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.ml-2 {
  margin-left: 1rem;
}
.main {
  img {
    width: 100%;
  }
}
.small {
  display: flex;
  padding-bottom: 1rem;
  width: 100%;
  justify-content: center;
  div {
    width: 100px;
    margin-right: 1rem;
    img {
      width: 100%;
      box-shadow: $light;
      cursor: pointer;
    }
  }
}
.desc {
  padding-top: 1rem;
  padding-bottom: 1rem;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .details-column {
      .details-item {
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
    }
  }
  section {
    border-bottom: $black;
    padding-bottom: 1rem;
    button {
      margin-top: 1rem;
      width: 100%;
    }
    .hidden {
      max-height: 100px;
      overflow: hidden;
      p {
        position: relative;
        &:not(:nth-child(-n + 2)) {
          display: none;
        }
        &:nth-child(2) {
          &:before {
            background: linear-gradient(transparent, white 57px);
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  h2,
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h4 {
    font-weight: normal;
  }
}
@media screen and (max-width: 980px) {
  .modal {
    width: 80%;
    height: auto;
  }
  .desc {
    header {
      flex-direction: column;

      div {
        margin-bottom: 1rem;
        h2 {
          font-size: 1.3rem;
          padding-bottom: 1rem;
        }
      }
    }
    .details {
      grid-template-columns: 1fr;
    }
  }
}
.small {
  flex-wrap: wrap;
  div {
    width: 80px;
  }
}
</style>