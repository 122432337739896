<template>
  <div class="wrapper" v-if="apartmentLoaded">
    <header class="move-up">
      <div>
        <h2>
          {{ apartment.city }} - <span>{{ apartment.district }}</span>
        </h2>
      </div>
    </header>
    <div class="cols">
      <base-card :columns="12" class="p">
        <template #default>
          <apartment-details
            :apartment="this.apartmentLoaded"
          ></apartment-details>
        </template>
      </base-card>
    </div>
    <div class="cols my">
      <base-card :columns="12" class="p"
        ><apartment-form :apartment="this.apartmentLoaded"></apartment-form
      ></base-card>
    </div>
  </div>
</template>

<script>
import ApartmentDetails from "../components/apartments/ApartmentDetails.vue";
import { mapActions, mapGetters } from "vuex";
import ApartmentForm from "../components/apartments/ApartmentForm.vue";

export default {
  components: { ApartmentDetails, ApartmentForm },
  data() {
    return {
      apartmentLoaded: null,
    };
  },
  methods: {
    ...mapActions(["fetchSingleApartment"]),
  },
  computed: {
    ...mapGetters(["getApartmentById"]),

    apartment() {
      return this.apartmentLoaded;
    },
  },
  async mounted() {
    this.apartmentLoaded = await this.fetchSingleApartment(
      this.$route.params.id
    );
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
.cols {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 980px) {
  .p {
    width: 90%;
  }
}
</style>